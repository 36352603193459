<template>
  <div class="select-group company-id">
    <label class="has-float-label">
      <div class="select-value-container"></div>
      <span class="select-group-float-label">{{ title }}</span>
      <span class="select-tick select-tick-closed"></span>
      <span class="invalid-feedback" role="alert"></span>
    </label>

    <div class="select-container">
      <ul>
        <li v-for="(item, index) in items" :key="index">
          {{ item }}
          <a
            href="#"
            :class="setClassOption(index, items.length)"
            :data-value="item.id"
            >{{ item.name }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "values", "title"],
  name: "SelectList",
  data() {
    return {};
  },
  computed: {
    items() {
      return this.values ? this.values : [];
    },
  },
  methods: {
    setClassOption(index, length) {
      if (index === 0) return "top";
      if (index === length - 1) return "last";
    },
  },
};
</script>

<style scoped></style>
